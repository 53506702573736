

body {
  font-family: cursive;
}

.botton-corner-suit {
  transform: rotate(180deg);
  position: inherit;
  float: right;
}

.center-suit {
  position: relative;
  left: 26px;
}

.front {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fb-login-button {
  transform: rotate(180deg);
}

.title {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: black;
  color: white;
  padding: 20px;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.title.fade {
  opacity: 0;
}

.scoreboard {
  position: absolute;

}

.card-deck {
  display: flex;
  justify-content: center;
  position: relative;
  transform: rotate(180deg);
}

.menu-button {
  width: 100%;
  background-color: #222 !important;
  border-color: #222 !important;
  
}

.menu-button:hover {
  background-color: #2c2c2c !important;
  
}

.not-ready {
  opacity: 0.2;
  pointer-events: none;
}

.round-cards {
  position: absolute;
  display: flex;
  justify-content: center;
}

.card-deck > * {
  display: flex;
  /* position: absolute; */
  transform-origin: bottom center;
  transition: transform 0.2s ease-in-out;
}

.card-deck > *:hover {
  transform: translateY(-20px);
}
/* 
.card-deck > *:not(:last-child) {
  transform: translateX(-50%) rotateZ(calc(-20deg * var(--index)));
  z-index: calc(100 - var(--index));
}

.card-deck > *:not(:last-child):hover {
  transform: translateX(-50%) rotateZ(calc(-20deg * var(--index))) translateY(-50px);
  z-index: 101;
} */

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.players-container {
  position: relative;
  background-color: rgba(58, 33, 21, 0.8);
  background-image: linear-gradient(43deg, #000000 0%, #c6252b 43%, #000000 100%);
  border-radius: 10px;
  border: 2px solid black;
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 100px;

}

/* .board-logo {
} */

.game-menu {
  animation: slideIn 0.5s forwards;

  position: fixed !important;
  z-index: 9999998;

  background: linear-gradient(180deg,#f6f6f6 0,#e6e6e6 100%);
  border-radius: 8px;
  box-shadow: 0 0 0 1px rgba(0,0,0,.08),2px 2px 10px 0 rgba(0,0,0,.23)!important;
  width: 46px!important;
  transition: 0.5s;


}

.game-menu .item {
  color: #606060;
  font-size: 0;
  padding: 12.5px 0;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.menu-hide {
  position: fixed !important;
  animation: slideOff 0.5s forwards;
}



@keyframes slideOff {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0);
  }
}

.player {
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  height: 35px;
  width: 35px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: bold;
  text-align: center;
  color: #000;

}

.info-container {
  margin-bottom: 20px;

}


.player-icon img {
  border-style:solid;
  box-shadow:0px 0px 21px 0px #000000 inset;
  border-radius: 50px;

}



.player-2 {
  transform: rotate(-90deg);
  top: 50%;
  left: -150px;
  order: 1;
}

.player-2 .info-container{
  transform: rotate(90deg);

}


.player-3 {
  top: -150px;
  left: 50%;
  order: 3;

}



.player-4 {
  transform: rotate(90deg);
  top: 50%;
  right: -150px;
  order: 2;
}

.player-4 .info-container {
  transform: rotate(-90deg);

}

.player-1 {
  height: 70px;
  width: 70px;
  transform: rotate(180deg);
  /* bottom: -150px;
  left: 50%; */
  order: 4;
  margin-top: 100px;
}

.player-1 .player-icon {
  transform: rotate(180deg);

}

/* Additional styling for the player image */
/* .player img {
  width: 150px;
  height: auto;
} */

/* Additional styling for the card deck */

.text-red {
  color: red;
}

.apple-player {
  width: 100%;
  max-width:660px;
  overflow:hidden;
  border-radius:10px;
}

.round-cards .playing-card {
  height: 150px;
  width: 80px;
  margin: -70px;
}


.player-1 .playing-card {
  margin: 5px;
  height: 150px;
  width: 80px;
  pointer-events: all;


}

.playing-card {
  height: 75px;
  width: 40px;
  pointer-events: none;
  padding: 2px;
  box-shadow: 1px 0px 11px;
  margin: -30px;
  background: #fff;
  
}

.disabled {
  pointer-events: none !important;

}



.spinning {
  animation: spin 2s linear infinite; 
  transform: translate(-50%, -50%);
  transition: transform 0.5s;
}

.move {
  /* top: 60px; */
  right: 40vh;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}


/* game settings */

.settings-container {
  background-color: #f0f0f039;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.settings-container h2 {
  margin-bottom: 20px;
}

.settings-container table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.settings-container th,
.settings-container td {
  padding: 10px;
  text-align: left;
}

.settings-container th {
  background-color: #f7f7f7;
}

.settings-container input[type="range"] {
  width: 100%;
}

.settings-container input[type="checkbox"] {
  margin-top: 0;
}

.settings-container button {
  margin-right: 10px;
}

.score-table-container {
  width: 25%;
}

.ps-menu-root .css-vj11vy {
  background-color: #eb3349;
}




.table-container {
  background-color: #f0f0f039;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  min-height: 90%;
  max-height: 90%;
}

.table-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container th,
.table-container td {
  padding: 10px;
  text-align: center;
}

.table-container thead th {
  background-color: #222;
  color: #fff;
  font-weight: bold;
}

.table-container tbody tr:nth-child(even) {
  background-color: #f7f7f7;
}

.table-container tbody td {
  font-size: 18px;
}

.table-container tbody td:first-child {
  text-align: left;
}

.table-container tfoot td {
  font-weight: bold;
}

.table-container tfoot .team-total {
  text-align: left;
}

.table-container tfoot .team-total span {
  font-weight: normal;
}

.table-container tfoot .winner {
  color: #008000;
}

.table-container tfoot .loser {
  color: #ff0000;
}

.winning-message {
  position: relative;
  color: #fff;
  top: -39px;
}


.bid-message {
  color: #fff;
}
